import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    fullScreenContainer: () => ({
      height: "100%",
      fontSize: "0.7vw",
      background: "#F2F5F5",
      padding: "1%",
      position : "relative"
    }),
    dashboardGridContainer: () => ({
      height: "100%",
      // padding : "1%",
      paddingBottom: "0",
    }),
    gridItemSection: () => ({
      background: "rgba(242, 245, 245, 1)",
      height: "100%",
      display: "flex",
    }),
    gridSectionLevelName: () => ({
      background: "rgba(7, 38, 36, 0.7)",
      padding: "6.5% 10%",
      display: "flex",
      justifyContent: "space-between",
      color: "white",
      borderRadius: "1vh",
      fontWeight: "600",
      letterSpacing: "0.07vh",
      width: "75%",
      // height : "5%"
    }),
    gridSectionMapContainer: () => ({
      height: "100%",
      // padding : "1%",
      // position : "relative"
    }),
    fullScreenIconSection: () => ({
      right: "0.5vw",
      bottom: "8vh",
      position: "absolute",
      width: "3vw",
      cursor: "pointer",
    }),

    liveButtonSection: () => ({
      display: "inline-flex",
      width : "6%",
      position : "absolute",
      right : "1vw",
      top : "0vw",
      zIndex : 1
    }),
  }),
  { index: 1 }
);
export default useStyles;
