import colorCodes from "./colors";

let theme = {
  defaultTheme: {
    palette: {
      login: {
       
      }
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  } 
};

export default theme;
