import { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import theme from "../../theme/theme";
import useStyles from "./styles";
import AccordienListItemDynamicSvg from "elements/AccordienListItemDynamicSvg";
import SsOptLoader from "../../assets/SS-Opt-loader.gif";

const ProgressBarAccordianListItem: React.FC<any> = (props) => {
  const { progressBarTitle, progressBarValue } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "light":
        setAppTheme(theme?.defaultTheme);
        break;
      case "dark":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const getGradientColor = useMemo(() => {
    if (progressBarValue > 0 && progressBarValue <= 15) {
      // Blue gradient

      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #5cc2c5 100%)`,
        progressValueColor: "#5cc2c5",
        progressBarValueUpdated: progressBarValue,
      };
    } else if (progressBarValue > 15 && progressBarValue <= 25) {
      // Blue gradient
      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #62caa1 100%)`,
        progressValueColor: "#62caa1",
        progressBarValueUpdated: progressBarValue,
      };
    } else if (progressBarValue > 25 && progressBarValue <= 30) {
      // Green gradient
      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #62caa1 80%, #6dda54 100%)`,
        progressValueColor: "#6dda54",
        progressBarValueUpdated: progressBarValue,
      };
    } else if (progressBarValue > 30 && progressBarValue <= 40) {
      // Green gradient
      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #62caa1 65%, #6dda54 100%)`,
        progressValueColor: "#6dda54",
        progressBarValueUpdated: progressBarValue,
      };
    } else if (progressBarValue > 40 && progressBarValue <= 50) {
      // Green gradient
      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #62caa1 50%, #6dda54 100%)`,
        progressValueColor: "#6dda54",
        progressBarValueUpdated: progressBarValue,
      };
    } else if (progressBarValue > 50 && progressBarValue <= 60) {
      // Yellow gradient
      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #62caa1 25%, #6dda54 60%, #cbc359 100%)`,
        progressValueColor: "#cbc359",
        progressBarValueUpdated: progressBarValue,
      };
    } else if (progressBarValue > 60 && progressBarValue <= 75) {
      // Yellow gradient
      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #62caa1 25%, #6dda54 50%, #cbc359 75%, #d5c059 100%)`,
        progressValueColor: "#d5c059",
        progressBarValueUpdated: progressBarValue,
      };
    } else if (progressBarValue > 75 && progressBarValue <= 85) {
      // Red gradient
      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #62caa1 20%, #6dda54 55%, #EAE11B 75%, #EAE11B 85%, #f28365 100%)`,
        progressValueColor: "#f28365",
        progressBarValueUpdated: progressBarValue,
      };
    } else if (progressBarValue > 85 && progressBarValue <= 100) {
      // Red gradient
      return {
        progressLinearGradient: `linear-gradient(to right, #54b8f8 0%, #6dda54 25%,  #EAE11B 60%, #F45B5B 100%)`,
        progressValueColor: "#F45B5B",
        progressBarValueUpdated: progressBarValue,
      };
    } else {
      return {
        progressLinearGradient: `linear-gradient(to right, #D3D3D3 100%)`,
        progressValueColor: "#ffffff",
        progressBarValueUpdated: progressBarValue,
      }; //Default gray color if value is out of range
    }
  }, [progressBarValue]);

  const styles = useMemo(() => {
    return {
      ...appTheme,
      progressBarValue: getGradientColor?.progressBarValueUpdated,
      progressBarValueColor: getGradientColor?.progressLinearGradient,
    };
  }, [appTheme, getGradientColor]);

  const {
    progressBarContainerStyle,
    progressBarTitleStyle,
    progressBarContainer,
    progressBarTrackerStyle,
    progressBarValueStyle,
    progressBarFooterStyle,
    progressBarTrackBlocks,
  } = useStyles(styles);

  const progressBarContainerUseMemo = useMemo(
    () => (
      <Grid item xs={11} className={progressBarContainer}>
        <div className={progressBarTrackerStyle}>
          <div className={progressBarValueStyle}>
            {Array.from({ length: 100 }, (_, i) => i + 1).map((item) => (
              <div className={progressBarTrackBlocks}></div>
            ))}
          </div>
        </div>
      </Grid>
    ),
    []
  );

  const progressBarFooterUseMemo = useMemo(
    () => (
      <Grid container xs={12} justifyContent={"space-between"}>
        <Grid item>0</Grid>
        <Grid item>25</Grid>
        <Grid item>50</Grid>
        <Grid item>75</Grid>
        <Grid item>100</Grid>
      </Grid>
    ),
    []
  );

  const accordienListItemDynamicSvgUseMemo = useMemo(
    () => (
      <AccordienListItemDynamicSvg
        color={getGradientColor?.progressValueColor}
      />
    ),
    [getGradientColor]
  );

  return (
    <>
      {useMemo(
        () => (
          <Grid container xs={12} className={progressBarContainerStyle}>
            {Object.keys(getGradientColor).length === 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={SsOptLoader} width={"10%"} />
              </div>
            ) : (
              <>
                <Grid item xs={12} className={progressBarTitleStyle}>
                  {progressBarTitle}
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                  >
                    {progressBarContainerUseMemo}
                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                      textAlign="center"
                      color={getGradientColor?.progressValueColor}
                      style={{
                        fontSize: "1.2vw",
                        fontWeight: "bold",
                        paddingLeft: "5%",
                      }}
                    >
                      {getGradientColor?.progressBarValueUpdated}%
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} className={progressBarFooterStyle}>
                  {progressBarFooterUseMemo}
                </Grid>

                <div
                  style={{
                    position: "absolute",
                    right: "1.5%",
                    top: "5%",
                    width: "5%",
                    height: "20%",
                  }}
                >
                  {accordienListItemDynamicSvgUseMemo}
                </div>
              </>
            )}
          </Grid>
        ),
        [getGradientColor, progressBarTitle]
      )}
    </>
  );
};
export default ProgressBarAccordianListItem;
