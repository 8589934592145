import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Map from "components/Map";
import FullScreenIcon from "../../assets/FullScreenIcon.svg";
import StadiumLevel1 from "../../assets/StadiumLayout/Level1.svg";
import StadiumLevel2 from "../../assets/StadiumLayout/Level2.svg";
import StadiumLevel3 from "../../assets/StadiumLayout/Level3.svg";
import StadiumLevel4 from "../../assets/StadiumLayout/Level4.svg";
import StadiumLevel5 from "../../assets/StadiumLayout/Level5.svg";
import StadiumLevel6 from "../../assets/StadiumLayout/Level6.svg";
import LiveButton from "../../assets/liveButton.gif";
import useStyles from "./styles";

const DashboardGridContainer = (props: any) => {
  const {
    onHandleFullScreen,
    fullScreenActive,
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    selectedLevel,
    heatMapDataSetLevel1,
    heatMapDataSetLevel2,
    heatMapDataSetLevel3,
    heatMapDataSetLevel4,
    heatMapDataSetLevel5,
    heatMapDataSetLevel6,
    gradientRange,
    playLiveEvent,
  } = props;

  const {
    dashboardGridContainer,
    gridItemSection,
    gridSectionLevelName,
    gridSectionMapContainer,
    fullScreenIconSection,
    liveButtonSection,
  } = useStyles();

  const mapOneUseMemo = useMemo(()=>(
    <Map
              layout={StadiumLevel1}
              heatMapRadius={heatMapRadius}
              heatMapOpacity={heatMapOpacity}
              heatMapMaxIntensity={heatMapMaxIntensity}
              heatMapWeight={heatMapWeight}
              fullScreenActive={fullScreenActive}
              selectedLevel={selectedLevel}
              gradientRange={gradientRange}
              heatMapData={heatMapDataSetLevel1}
            />
  ),[
    StadiumLevel1,
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    fullScreenActive,
    selectedLevel,
    gradientRange,
    heatMapDataSetLevel1,
  ])

  const mapTwoUseMemo = useMemo(()=>(
    <Map
              layout={StadiumLevel2}
              heatMapRadius={heatMapRadius}
              heatMapOpacity={heatMapOpacity}
              heatMapMaxIntensity={heatMapMaxIntensity}
              heatMapWeight={heatMapWeight}
              fullScreenActive={fullScreenActive}
              selectedLevel={selectedLevel}
              gradientRange={gradientRange}
              heatMapData={heatMapDataSetLevel2}
            />
  ),[
    StadiumLevel2,
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    fullScreenActive,
    selectedLevel,
    gradientRange,
    heatMapDataSetLevel2,
  ])

  const mapThreeUseMemo = useMemo(()=>(
    <Map
              layout={StadiumLevel3}
              heatMapRadius={heatMapRadius}
              heatMapOpacity={heatMapOpacity}
              heatMapMaxIntensity={heatMapMaxIntensity}
              heatMapWeight={heatMapWeight}
              fullScreenActive={fullScreenActive}
              selectedLevel={selectedLevel}
              gradientRange={gradientRange}
              heatMapData={heatMapDataSetLevel3}
            />
  ),[
    StadiumLevel3,
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    fullScreenActive,
    selectedLevel,
    gradientRange,
    heatMapDataSetLevel3,
  ])

  const mapFourUseMemo = useMemo(()=>(
    <Map
              layout={StadiumLevel4}
              heatMapRadius={heatMapRadius}
              heatMapOpacity={heatMapOpacity}
              heatMapMaxIntensity={heatMapMaxIntensity}
              heatMapWeight={heatMapWeight}
              fullScreenActive={fullScreenActive}
              selectedLevel={selectedLevel}
              gradientRange={gradientRange}
              heatMapData={heatMapDataSetLevel4}
            />
  ),[
    StadiumLevel4,
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    fullScreenActive,
    selectedLevel,
    gradientRange,
    heatMapDataSetLevel4,
  ])

  const mapFiveUseMemo = useMemo(()=>(
    <Map
              layout={StadiumLevel5}
              heatMapRadius={heatMapRadius}
              heatMapOpacity={heatMapOpacity}
              heatMapMaxIntensity={heatMapMaxIntensity}
              heatMapWeight={heatMapWeight}
              fullScreenActive={fullScreenActive}
              selectedLevel={selectedLevel}
              gradientRange={gradientRange}
              heatMapData={heatMapDataSetLevel5}
            />
  ),[
    StadiumLevel5,
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    fullScreenActive,
    selectedLevel,
    gradientRange,
    heatMapDataSetLevel5,
  ])

  const mapSixUseMemo = useMemo(()=>(
    <Map
              layout={StadiumLevel6}
              heatMapRadius={heatMapRadius}
              heatMapOpacity={heatMapOpacity}
              heatMapMaxIntensity={heatMapMaxIntensity}
              heatMapWeight={heatMapWeight}
              fullScreenActive={fullScreenActive}
              selectedLevel={selectedLevel}
              gradientRange={gradientRange}
              heatMapData={heatMapDataSetLevel6}
            />
  ),[
    StadiumLevel6,
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    fullScreenActive,
    selectedLevel,
    gradientRange,
    heatMapDataSetLevel6,
  ])

  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 2, md: 2 }}
      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      className={dashboardGridContainer}
    >
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Grid className={gridItemSection}>
          <Grid
            style={{ display: "flex", width: "100%", position: "relative" }}
          >
            <Grid className={gridSectionLevelName}>LEVEL 1</Grid>
            {playLiveEvent && 
             <Grid className={liveButtonSection}>
             {" "}
             <img src={LiveButton} alt="LiveButton" width={"100%"} />
           </Grid>
            }
           
          </Grid>

          <Grid className={gridSectionMapContainer}>
            {mapOneUseMemo}
            <Grid className={fullScreenIconSection}>
              <img
                src={FullScreenIcon}
                alt="FullScreenIcon"
                width={"100%"}
                height={"100%"}
                onClick={() => onHandleFullScreen(1)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Grid className={gridItemSection}>
          <Grid
            style={{ display: "flex", width: "100%", position: "relative" }}
          >
            <Grid className={gridSectionLevelName}>LEVEL 2</Grid>
            {playLiveEvent && 
             <Grid className={liveButtonSection}>
             {" "}
             <img src={LiveButton} alt="LiveButton" width={"100%"} />
           </Grid>
            }
          </Grid>{" "}
          <Grid className={gridSectionMapContainer}>
            {mapTwoUseMemo}
            <Grid className={fullScreenIconSection}>
              <img
                src={FullScreenIcon}
                alt="FullScreenIcon"
                width={"100%"}
                height={"100%"}
                onClick={() => onHandleFullScreen(2)}
              />
            </Grid>
          </Grid>
        </Grid>{" "}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Grid className={gridItemSection}>
          <Grid
            style={{ display: "flex", width: "100%", position: "relative" }}
          >
            <Grid className={gridSectionLevelName}>LEVEL 3</Grid>
            {playLiveEvent && 
             <Grid className={liveButtonSection}>
             {" "}
             <img src={LiveButton} alt="LiveButton" width={"100%"} />
           </Grid>
            }
          </Grid>{" "}
          <Grid className={gridSectionMapContainer}>
            {mapThreeUseMemo}
            <Grid className={fullScreenIconSection}>
              <img
                src={FullScreenIcon}
                alt="FullScreenIcon"
                width={"100%"}
                height={"100%"}
                onClick={() => onHandleFullScreen(3)}
              />
            </Grid>
          </Grid>
        </Grid>{" "}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Grid className={gridItemSection}>
          <Grid
            style={{ display: "flex", width: "100%", position: "relative" }}
          >
            <Grid className={gridSectionLevelName}>LEVEL 4</Grid>
            {playLiveEvent && 
             <Grid className={liveButtonSection}>
             {" "}
             <img src={LiveButton} alt="LiveButton" width={"100%"} />
           </Grid>
            }
          </Grid>{" "}
          <Grid className={gridSectionMapContainer}>
            {mapFourUseMemo}
            <Grid className={fullScreenIconSection}>
              <img
                src={FullScreenIcon}
                alt="FullScreenIcon"
                width={"100%"}
                height={"100%"}
                onClick={() => onHandleFullScreen(4)}
              />
            </Grid>
          </Grid>
        </Grid>{" "}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Grid className={gridItemSection}>
          <Grid
            style={{ display: "flex", width: "100%", position: "relative" }}
          >
            <Grid className={gridSectionLevelName}>LEVEL 5</Grid>
            {playLiveEvent && 
             <Grid className={liveButtonSection}>
             {" "}
             <img src={LiveButton} alt="LiveButton" width={"100%"} />
           </Grid>
            }
          </Grid>{" "}
          <Grid className={gridSectionMapContainer}>
          {mapFiveUseMemo}
            <Grid className={fullScreenIconSection}>
              <img
                src={FullScreenIcon}
                alt="FullScreenIcon"
                width={"100%"}
                height={"100%"}
                onClick={() => onHandleFullScreen(5)}
              />
            </Grid>
          </Grid>
        </Grid>{" "}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Grid className={gridItemSection}>
          <Grid
            style={{ display: "flex", width: "100%", position: "relative" }}
          >
            <Grid className={gridSectionLevelName}>LEVEL 6</Grid>
            {playLiveEvent && 
             <Grid className={liveButtonSection}>
             {" "}
             <img src={LiveButton} alt="LiveButton" width={"100%"} />
           </Grid>
            }
          </Grid>{" "}
          <Grid className={gridSectionMapContainer}>
            {mapSixUseMemo}
            <Grid className={fullScreenIconSection}>
              <img
                src={FullScreenIcon}
                alt="FullScreenIcon"
                width={"100%"}
                height={"100%"}
                onClick={() => onHandleFullScreen(6)}
              />
            </Grid>
          </Grid>
        </Grid>{" "}
      </Grid>
    </Grid>
  );
};

export default DashboardGridContainer;
