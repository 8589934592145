import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";

interface Props {
  color: string;
 
}

const AccordienListItemDynamicSvg: React.FC<Props> = (props) => {
  const {color} = props;

  const {  } = useStyles();
  
  

  return (
    <>
    
    <svg width="100%" height="100%" viewBox="0 0 19 15" fill="none" style={{ position: 'absolute', top: 0, left: 0, padding: 0, margin: 0 }} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 14H18" stroke={color ? color : "#ffffff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 11L5.72222 5L11.3889 8L18 1" stroke={color ? color : "#ffffff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 6V1H13" stroke={color ? color : "#ffffff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
   
    </>
  );
};

export default AccordienListItemDynamicSvg;
