const levelsList = [
  {
    id: 1,
    title: "level1",
    event: "NBA-04-10-2023",
    level: "Level 1 - Event",
    start: "20-07-2023 | 3:00 PM",
    end: "20-07-2023 | 5:00 PM",
    category1: {
      id: "",
      title: "beverages",
      listData: [
        {
          id: "",
          title: "2-South craft Bevarges",
          count: 50,
          radius: 30,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.428533723339057, lng: -98.43726832498021 }, //1
            { lat: 29.427550335922353, lng: -98.43815718366031 }, //2
            { lat: 29.427004454077294, lng: -98.43817436856241 }, //3
            { lat: 29.42601056439931, lng: -98.43798969257385 }, //4
          ],
        },

        {
          id: "",

          title: "4 - South L Bar",

          count: 70,
          radius: 25,
          observation: "down",
          opacity: 0.6,
          // location: [
          //   { lat: 40.753658384158124, lng: -73.84584634141573 },
          //   { lat: 40.755558384158124, lng: -73.84554634141573 },
          //   { lat: 40.755058384158124, lng: -73.84534634141573 },
          //   //  { lat: 40.759858384158124, lng: -73.84554634141573 },
          // ],
        },
      ],
    },

    category2: {
      id: "",
      title: "concessions",
      listData: [
        {
          id: "",

          title: "6 - SA Grill",

          count: 70,
          radius: 25,
          observation: "up",
          opacity: 0.6,

          location: [
            { lat: 29.42504239752662, lng: -98.43335614733205 },
            { lat: 29.424503153106534, lng: -98.43403008660217 },
          ],
        },

        {
          id: "",

          title: "2 - Grab & Go",
          radius: 25,
          count: 80,
          
          opacity: 0.6,
          observation: "down",
          // location: [{ lat: 40.756558384158124, lng: -73.84554634141573 }],
        },
      ],
    },

    category3: {
      id: "",
      title: "restRoom",
      listData: [
        {
          id: "",

          title: "1A13B - Restroom",
          radius: 25,
          count: 20,
          opacity: 0.6,
          observation: "up",
          location: [
            { lat: 29.42804239752662, lng: -98.43335614733205 },
            { lat: 29.429003153106534, lng: -98.43403008660217 },
          ],
        },
        {
          id: "",
          title: "1B40B - Restroom",
          count: 65,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "1B54A - Restroom",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "1B41B - Restroom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "1B33C - Restroom",
          count: 48,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "1C47J - Restroom",
          count: 51,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
      ],
    },
  },
  {
    id: 2,
    title: "level2",

    event: "NBA-04-10-2023",
    level: "Level 2 - Charter",
    start: "20-07-2023 | 5:00 PM",
    end: "20-07-2023 | 7:00 PM",
    category1: {
      id: "",
      title: "beverages",
      listData: [
        {
          id: "",

          title: "2- South craft Bevarges",

          count: 85,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
              { lat: 29.426031225633036,lng: -98.43817848694509 },
              { lat: 29.426215578787772,lng: -98.43803486096917 },
             // { lat: 29.426380179536043,lng: -98.43839770553988 },
             // { lat: 29.426156322453103,lng: -98.43845062037312 },
          ],
        },
        {
          id: "",
          title: "4 - South L Bar",
          count: 65,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "8 - South Main Bar",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "22 - Jack Daniel's Bar",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "26 - North L Bar",
          count: 48,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "26 - North Craft Beverages",
          count: 51,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
      ],
    },

    category2: {
      id: "",
      title: "concessions",
      listData: [
        {
          id: "",

          title: "2 - Grab & Go",

          count: 62,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
           { lat: 29.427973500991406, lng: -98.43755106820824 },
           { lat: 29.428460710000177,lng: -98.4378005238506 },
           { lat: 29.429460710000177,lng: -98.4378005238506 },
           { lat: 29.429860710000177,lng: -98.4378005238506 },
          
          ],
        },

        {
          id: "",

          title: "4 - South Crust",

          count: 80,
          radius: 25,

          observation: "down",
          location: [{ lat: 40.756558384158124, lng: -73.84554634141573 }],
        },
        {
          id: "",
          title: "6 - SA Grill",
          count: 85,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "20 - Local & Co.",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "24 - North Roadhouse",
          count: 45,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "26 - North Crust",
          count: 35,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "26 - Grab & Go",
          count: 75,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
        {
          id: "",
          title: "22 - River Wok - Bao'd Up",
          count: 65,
          radius: 25,
          observation: "down",
          opacity: 0.6,
        },
      ],
    },

    category3: {
      id: "",
      title: "restRoom",
      listData: [
        {
          id: "",

          title: "20A1 - Restroom",

          count: 45,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.42804239752662, lng: -98.43335614733205 },
            { lat: 29.427003153106534, lng: -98.43403008660217 },
            { lat: 29.42604239752662, lng: -98.43335614733205 },
            { lat: 29.429003153106534, lng: -98.43403008660217 },
          ],
        },


        {
          id: "",
          title: "2 - Men RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "824A - Women RestRoom",
          count: 28,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "28 - Men RestRoom",
          count: 58,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "28 - Women RestRoom",
          count: 88,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "28 - RestRoom",
          count: 66,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "96A - Women RestRoom",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "96A - Restroom",
          count: 44,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "10A16 - Restroom",
          count: 33,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "10A16 - Men Restroom",
          count: 22,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "20 - Men Restroom",
          count: 77,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "20 - Restroom",
          count: 88,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
      ],
    },
  },
  {
    id: 3,
    title: "level3",

    event: "NBA-04-10-2023",
    level: "Level 3 - Plaza",
    start: "20-07-2023 | 5:00 PM",
    end: "20-07-2023 | 7:00 PM",
    category1: {
      id: "",
      title: "beverages",
      listData: [
        {
          id: "",
    
          title: "103-Bru Co",
    
          count: 20,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.428533723339057, lng: -98.43726832498021 }, 
            { lat: 29.427550335922353, lng: -98.43815718366031 }, 
            { lat: 29.427004454077294, lng: -98.43817436856241 }, 
            { lat: 29.42601056439931, lng: -98.43798969257385 }, 
          ],
        },
    
        {
          id: "",
    
          title: "104-Tended Bar",
    
          count: 30,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          location: [
            { lat: 29.42844754221986, lng: -98.43715042732808 },
            { lat: 29.42894754221986, lng: -98.43715042732808 },
            { lat: 29.42854754221986, lng: -98.43715042732808 },
          ],
        },
    
        {
          id: "",
    
          title: "109-Cocktail Co",
    
          count: 40,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          // location: [
          //   { lat: 29.42844754221986, lng: -98.43715042732808 },
          //   { lat: 29.42894754221986, lng: -98.43715042732808 },
          //   { lat: 29.42854754221986, lng: -98.43715042732808 },
          // ],
        },
    
        {
          id: "",
    
          title: "112-Buy the Bottle",
    
          count: 55,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          // location: [
          //   { lat: 29.42844754221986, lng: -98.43715042732808 },
          //   { lat: 29.42894754221986, lng: -98.43715042732808 },
          //   { lat: 29.42854754221986, lng: -98.43715042732808 },
          // ],
        },
    
        {
          id: "",
    
          title: "115-Rock & Brews",
    
          count: 66,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          // location: [
          //   { lat: 29.42844754221986, lng: -98.43715042732808 },
          //   { lat: 29.42894754221986, lng: -98.43715042732808 },
          //   { lat: 29.42854754221986, lng: -98.43715042732808 },
          // ],
        },
    
        {
          id: "",
    
          title: "122-Jack Daniel's Bar",
    
          count: 45,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          // location: [
          //   { lat: 29.42844754221986, lng: -98.43715042732808 },
          //   { lat: 29.42894754221986, lng: -98.43715042732808 },
          //   { lat: 29.42854754221986, lng: -98.43715042732808 },
          // ],
        },
    
        {
          id: "",
    
          title: "122-Smart Store",
    
          count: 40,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          // location: [
          //   { lat: 29.42844754221986, lng: -98.43715042732808 },
          //   { lat: 29.42894754221986, lng: -98.43715042732808 },
          //   { lat: 29.42854754221986, lng: -98.43715042732808 },
          // ],
        },
    
        {
          id: "",
    
          title: "124-Budweiser Stand",
    
          count: 20,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          // location: [
          //   { lat: 29.42844754221986, lng: -98.43715042732808 },
          //   { lat: 29.42894754221986, lng: -98.43715042732808 },
          //   { lat: 29.42854754221986, lng: -98.43715042732808 },
          // ],
        },
    
        {
          id: "",
    
          title: "126-Cocktail Co.",
    
          count: 35,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          // location: [
          //   { lat: 29.42844754221986, lng: -98.43715042732808 },
          //   { lat: 29.42894754221986, lng: -98.43715042732808 },
          //   { lat: 29.42854754221986, lng: -98.43715042732808 },
          // ],
        },
      ],
    },

    category2: {
      id: "",
      title: "concessions",
      listData: [
        {
          id: "",
    
          title: "103-Fan Favorites",
    
          count: 60,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.426116818210758,lng: -98.43671954940037 },
            { lat: 29.426090482040678,lng: -98.43716554585185 },
            { lat: 29.426301171210152,lng: -98.43693876799516 },
            { lat: 29.426255082991666,lng: -98.43741500149422 },
          ],
        },
    
        {
          id: "",
    
          title: "105-L. Smokes SATX",
          radius: 25,
          count: 80,
          opacity: 0.6,
          observation: "down",
          location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
            { lat: 29.424503153106534, lng: -98.43403008660217 },
          ],
        },
    
        {
          id: "",
    
          title: "106-Fast Break",
          radius: 25,
          count: 50,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "109-Whataburger",
          radius: 25,
          count: 40,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "110-Pizza Hut",
          radius: 25,
          count: 30,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "111-Cookies & Cream",
          radius: 25,
          count: 57,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "111-Birria Tacos",
          radius: 25,
          count: 77,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "112-Nacho Bar",
          radius: 25,
          count: 88,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "117-Seoul Stop",
          radius: 25,
          count: 44,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "118-The Kernel",
          radius: 25,
          count: 33,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "121-TL&Chicken",
          radius: 25,
          count: 25,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "120-Spurs Pop",
          radius: 25,
          count: 74,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "121-Nitro Ice Cream",
          radius: 25,
          count: 35,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "123-Burger Kitchen",
          radius: 25,
          count: 80,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "124-Pizza Hut",
          radius: 25,
          count: 50,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "125-Paletas",
          radius: 25,
          count: 70,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "126-Fan Faves Express",
          radius: 25,
          count: 55,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
    
        {
          id: "",
    
          title: "126-The Kernel",
          radius: 25,
          count: 44,
          opacity: 0.6,
          observation: "down",
          // location: [ { lat: 29.42504239752662, lng: -98.43335614733205 },
          //   { lat: 29.424503153106534, lng: -98.43403008660217 },
          // ],
        },
      ],
    },
    category3: {
      id: "",
      title: "restRoom",
      listData: [
        {
          id: "",

          title: "116 - RestRoom",

          count: 45,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          /*location: [
            { lat: 40.756689763120505, lng: -73.84479045466031 },
            { lat: 40.7566984305309, lng: -73.84481047867867 },
            { lat: 40.75656625239948, lng: -73.84478187293817 },
            { lat: 40.75639940421901, lng: -73.8450908149356 },
          ],*/
        },

        {
          id: "",
          title: "117 - Men RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "117 - Women RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "113 - RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "112 - Women RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "112 - Men RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "102 - RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "103 - Mens Restroom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "103 - Women Restroom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "127 - Restroom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "127 - Men Restroom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "127 - Women Restroom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

       
      ],
    },
  },
  {
    id: 4,
    title: "level4",

    event: "NBA-04-10-2023",
    level: "Level 4 - Terrace",
    start: "20-07-2023 | 5:00 PM",
    end: "20-07-2023 | 7:00 PM",
    category1: {
      id: "",
      title: "beverages",
      listData: [
        {
          id: "",

          title: "4D14 - Bar",

          count: 90,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.427038579861804,lng: -98.43849597594446 },
            { lat: 29.42782541189712, lng: -98.43827675734967 },
            { lat: 29.4260871481648,  lng: -98.43829187587345 },
            { lat: 29.424873980180467,lng: -98.43811045358808 },
          ],
        },

        {
          id: "",

          title: "4A28A - Bar",

          count: 40,
          opacity: 0.6,
          observation: "down",
          radius: 25,
          location: [
            { lat: 29.426077313953073, lng: -98.43733184961343 },
            { lat: 29.426577313953073, lng: -98.43733184961343 },
            { lat: 29.426877313953073, lng: -98.43733184961343 },

          ],
        },
      ],
    },

    category2: {
      id: "",
      title: "concessions",
      listData: [
        {
          id: "",

          title: "4D15G - Terrace Restaurant & Ledge Boxes",

          count: 60,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.4287701523462,   lng: -98.43792147204084 },
            { lat: 29.4297701523462,   lng: -98.43792147204084 },
            { lat: 29.4299701523462,   lng: -98.43792147204084 },
            { lat: 29.4299901523462,   lng: -98.43792147204084 },
          
          ],
        },

        {
          id: "",

          title: "4D18 - Superbox 1",

          count: 80,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          location: [
            { lat: 29.428401454975308,lng: -98.43678758275738 },
            { lat: 29.428513381104395,lng: -98.43715042732808 },
            { lat: 29.428256609213392,lng: -98.43705215692351 },
            { lat: 29.428223689693255,lng: -98.43690097168573 },
          
          ],
        },
        {
          id: "",
          title: "4A28 - Superbox 2",
          count: 80,
          radius: 25,
          opacity: 0.6,
          observation: "down",         
        },
      ],
    },

    category3: {
      id: "",
      title: "restRoom",
      listData: [
        {
          id: "",

          title: "2-South craft Bevarges",

          count: 45,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.42804239752662, lng: -98.43335614733205 },
            { lat: 29.429003153106534, lng: -98.43403008660217 },
          ],
        },
        {
          id: "",
          title: "4C18 - Men RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4C14 - Women RestRoom",
          count: 28,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4A12C - Men RestRoom",
          count: 22,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4A12E - Women RestRoom",
          count: 44,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4A04 - Men RestRoom",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4A26 - Women RestRoom",
          count: 66,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4B07 - Mens Restroom",
          count: 77,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4A18 - Women Restroom",
          count: 88,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4B11 - Women Restroom",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "4B18 - Women Restroom",
          count: 66,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
      ],
    },
  },
  { 
    id: 5,
    title: "level5",

    event: "NBA-04-10-2023",
    level: "Level 5 - Balcony",
    start: "20-07-2023 | 5:00 PM",
    end: "20-07-2023 | 7:00 PM",
    category1: {
      id: "",
      title: "beverages",
      listData: [
        {
          id: "",

          title: "201 - La Gloria Bar",

          count: 90, 
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.427380946344552, lng: -98.4386925167536 }, 
            { lat: 29.427580946344552, lng: -98.4386925167536 }, 
            { lat: 29.427680946344552, lng: -98.4386925167536 }, 
            { lat: 29.427880946344552, lng: -98.4386925167536 },
          ],
        },

        {
          id: "",

          title: "202 - Ultra Zone",

          count: 40,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          location: [
            { lat: 29.425596677587297, lng: -98.43608457140162 },
            { lat: 29.425896677587297, lng: -98.43608457140162 },
            { lat: 29.425696677587297, lng: -98.43608457140162 },
            { lat: 29.425396677587297, lng: -98.43608457140162 },
          ],
        },
        {
          id: "",

          title: "206 - Bru Co.",

          count: 30, 
          radius: 25,
          observation: "up",
          opacity: 0.6,
          // location: [
          //   { lat: 29.427380946344552, lng: -98.4386925167536 }, 
          //   { lat: 29.427580946344552, lng: -98.4386925167536 }, 
          //   { lat: 29.427680946344552, lng: -98.4386925167536 }, 
          //   { lat: 29.427880946344552, lng: -98.4386925167536 },
          // ],
        },

        {
          id: "",

          title: "207A - Buckle Bar",

          count: 40,
          radius: 25,
          opacity: 0.6,
          observation: "down",
          // location: [
          //   { lat: 29.425596677587297, lng: -98.43608457140162 },
          //   { lat: 29.425896677587297, lng: -98.43608457140162 },
          //   { lat: 29.425696677587297, lng: -98.43608457140162 },
          //   { lat: 29.425396677587297, lng: -98.43608457140162 },
          // ],
        },

        {
          id: "",
          title: "210 - Bru Co.",
          count: 43,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "216 - Tito's Bar",
          count: 53,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "222 - Bru Co.",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "223B - Buy The Bottle",
          count: 68,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "226B - Bru Co.",
          count: 78,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "230 - Ultra Zone",
          count: 33,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
      ],
    },

    category2: {
      id: "",
      title: "concessions",
      listData: [
        {
          id: "",

          title: "201 - Burgerteca ",

          count: 60,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.4287701523462, lng: -98.43685561611437 },
            { lat: 29.4298701523462, lng: -98.43685561611437 },
            { lat: 29.4283701523462, lng: -98.43685561611437 },
            { lat: 29.4247701523462, lng: -98.43685561611437 },
          ],
        },

        {
          id: "",

          title: "206 - Fast Break Food",

          count: 80,
          radius: 25,
          opacity: 0.6,
          observation: "down",
         location: [
          { lat: 29.42769039198029,  lng: -98.43451224492854 },
          { lat: 29.42770355985879,  lng: -98.43476170057092 },
          { lat: 29.42569039198029,  lng: -98.43451224492854 },
          { lat: 29.42369039198029,  lng: -98.43451224492854 },
          

        ],
        },

        {
          id: "",
          title: "206 - Pizza Hut/Paletas",
          count: 43,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "208 - Birria Tacos",
          count: 23,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "209 - Spurs Pop",
          count: 26,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "209 - Fan Favorites",
          count: 36,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "215 - TL&Chicken",
          count: 43,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "217 - Community Kitchen",
          count: 49,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "223A - Pizza Hut",
          count:63,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "223 - Fast Break",
          count: 44,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "225 -  Nacho Bar",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "226A - Fan Favorites",
          count: 66,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },

        {
          id: "",
          title: "231 - La Gloria",
          count: 77,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },


      ],
    },

    category3: {
      id: "",
      title: "restRoom",
      listData: [
        {
          id: "",

          title: "221 - RestRoom",

          count: 45,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          /*location: [
            { lat: 40.756689763120505, lng: -73.84479045466031 },
            { lat: 40.7566984305309, lng: -73.84481047867867 },
            { lat: 40.75656625239948, lng: -73.84478187293817 },
            { lat: 40.75639940421901, lng: -73.8450908149356 },
          ],*/
        },

        {
          id: "",
          title: "221 - Men RestRoom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "222 - Women RestRoom",
          count: 28,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "227 - Men RestRoom",
          count: 22,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "227 - Women RestRoom",
          count: 44,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "227 - RestRoom",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "230 - Women RestRoom",
          count: 66,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "202 - Mens Restroom",
          count: 77,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "205 - Restroom",
          count: 88,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "205 - Women Restroom",
          count: 55,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "206 - Men Restroom",
          count: 44,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "206 - Men Restroom",
          count: 66,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "210 - Women Restroom",
          count: 75,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "210 - Men Restroom",
          count: 41,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "211 - Women Restroom",
          count: 52,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "211 - Restroom",
          count: 63,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "214 - Restroom",
          count: 45,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
        {
          id: "",
          title: "214 - Men Restroom",
          count: 38,
          radius: 25,
          observation: "down",
          opacity: 0.6,
       
        },
       
      ],
    },
  },
  {
    id: 6,
    title: "level6",

    event: "NBA-04-10-2023",
    level: "Level 6 - Mechanical",
    start: "20-07-2023 | 5:00 PM",
    end: "20-07-2023 | 7:00 PM",
    category1: {
      id: "",
      title: "beverages",
      listData: [
        {
          id: "",

          title: " 6C01A - Restroom",
          count: 45,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          location: [
            { lat: 29.427051747824784, lng: -98.43904024280052 },
            { lat: 29.426933236096477,lng: -98.43922166508587 },
            { lat: 29.4268871481648, lng: -98.43909315763374 },
            { lat: 29.427334858616025, lng: -98.43935773179989 },
          ],
        },

        // {
        //   id: "",

        //   title: "South Main Bar",

        //   count: 40,
        //   radius: 25,
        //   observation: "down",
        //   //location: [{ lat: 40.75622740802814, lng: -73.85007594512238 }],
        // },
      ],
    },

    // category2: {
    //   id: "",
    //   title: "concessions",
    //   listData: [
    //     {
    //       id: "",

    //       title: "2-South craft Bevarges",

    //       count: 60,
    //       radius: 25,
    //       observation: "up",
    //       opacity: 0.6,
    //       location: [
    //         { lat: 29.428987419813033, lng: -98.4374225607561 },
    //         { lat: 29.42915201606939,  lng: -98.4370672754473 },
    //         { lat: 29.429402201868157, lng: -98.43647765301988 },
    //         { lat: 29.429171767602224, lng: -98.43791391277895 },

    //       ],
    //     },

    //     {
    //       id: "",

    //       title: "4 South Lbar",

    //       count: 80,
    //       radius: 25,
    //       observation: "down",
    //       opacity: 0.6,
    //       location: [
    //         { lat: 29.425379402867296,  lng: -98.43639450113909 },
    //         { lat: 29.42534648241465,   lng: -98.43715798658998 },
    //         { lat: 29.42539257104539,   lng: -98.43628111221075 },
    //         { lat: 29.425458411910157,  lng: -98.43695388651894 },
    //       ],
    //     },
    //   ],
    // },

    category3: {
      id: "",
      title: "restRoom",
      listData: [
        {
          id: "",

          title: " 6C01A - TOILET",

          count: 45,
          radius: 25,
          observation: "up",
          opacity: 0.6,
          // location: [
          //   { lat: 29.42838828718731,  lng: -98.4349128858087 },
          //   { lat: 29.42796691706989,  lng: -98.4351396636654 },
          //   { lat: 29.42843437443784,  lng: -98.43554786380744 },
          //   { lat: 29.42740728218006,  lng: -98.43501871547515 },
            
          // ],
        },
      ],
    },
  },
];

export default levelsList;
