import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Map from "components/Map";
import ArrowRightIcon from "../../assets/arrow-right.svg";
import MinimizeIcon from "../../assets/MinimizeIcon.svg";
import LiveButton from "../../assets/liveButton.gif";
import useStyles from "./styles";

const DashboardFullScreen = (props: any) => {
  const {
    fullScreenContainer,
    gridItemSection,
    gridSectionLevelName,
    gridSectionMapContainer,
    fullScreenIconSection,
    liveButtonSection
  } = useStyles();

  const {
    setFullScreenActive,
    fullScreenActive,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    heatMapRadius,
    selectedLevel,
    mapLayout,
    heatMapData,
    gradientRange,
    onHandleMinimize,
    playLiveEvent
  } = props;

  const mapFullScreenUseMemo = useMemo(()=>(
    <Map
              layout={mapLayout}
              fullScreenActive={fullScreenActive}
              heatMapRadius={heatMapRadius}
              heatMapOpacity={heatMapOpacity}
              heatMapMaxIntensity={heatMapMaxIntensity}
              heatMapWeight={heatMapWeight}
              selectedLevel={selectedLevel}
              heatMapData={heatMapData}
              gradientRange={gradientRange}
              pageName={"fullScreenView"}
            />
  ),[
    mapLayout,
    fullScreenActive,
    heatMapRadius,
    heatMapOpacity,
    heatMapWeight,
    heatMapMaxIntensity,
    selectedLevel,
    gradientRange,
    heatMapData,
  ])

  return (
    <Grid container className={fullScreenContainer}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid className={gridItemSection} xs={12}>
          <Grid xs={1.5} style={{ padding: "1%",  }}>
            <Grid className={gridSectionLevelName}>
              <div>
                <img
                  src={ArrowRightIcon}
                  onClick={onHandleMinimize}
                  alt="ArrowRightIcon"
                  width={"100%"}
                  height={"100%"}
                  style={{ cursor: "pointer", width : "0.9vw", display : "flex" }}
                />
              </div>
              <div>LEVEL {selectedLevel}</div>
            </Grid>
            {
              playLiveEvent && 
              <Grid className={liveButtonSection}>
             {" "}
             <img src={LiveButton} alt="LiveButton" width={"100%"} />
           </Grid>
            }
            
          </Grid>

          <Grid xs={10.5} className={gridSectionMapContainer}>
            {mapFullScreenUseMemo}
            {/* <Grid className={fullScreenIconSection}>
              <img
                src={MinimizeIcon}
                alt="MinimizeIcon"
                width={"100%"}
                height={"100%"}
                onClick={onHandleMinimize}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardFullScreen;
